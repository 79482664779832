import React from 'react';
import './style.css';
import { Row, Col } from 'react-flexbox-grid';
import Collapsible from 'react-collapsible';


function Faq(props) {
  return (
    <div className={'faq'}>
        <h2>Häufige Fragen & Antworten</h2>
        <Collapsible trigger="Was ist TreeMates?" triggerTagName={'div'} transitionTime={200}>
            <p><a href="https://www.treemates.net" target="_blank">TreeMates</a> ist eine Bewegung von Menschen, die sich <b>digital für den Klimaschutz</b> einsetzen. Hierfür stellen wir, als <b>gemeinnütziger Verein</b>, Werkzeuge zur Verfügung, mit denen jeder Mensch die Möglichkeit hat, ganz einfach und nachhaltig einen Baum zu pflanzen. Wir nennen das <b>One-Click-Planting</b>.</p>
        </Collapsible>
        <Collapsible trigger="Wo werden die Bäume gepflanzt?" triggerTagName={'div'} transitionTime={200}>
            <p>TreeMates pflanzt die Bäume nicht selbst, sondern arbeitet ausschließlich mit <b>zertifizierten Partnerorganisationen</b> zusammen. Einen Überblick über unsere aktiven Partnerschaften und Zertifizierungen findest du auf <a href="https://www.treemates.net/partner/" target="_blank">unserer Website</a>.</p>
        </Collapsible>
        <Collapsible trigger="Was passiert mit meinem Geld?" triggerTagName={'div'} transitionTime={200}>
            <p>Mindestens <b>80% deines Geldes</b> fließen direkt an unsere Partnerorganisationen und in den Klimaschutz. Mit dem <b>restlichen Teil</b> finanzieren wir unsere Infrastruktur, diverse Gebühren und versuchen, noch mehr Menschen für unsere Bewegung zu begeistern. Der Preis je Baum variiert von Organisation zu Organisation bzw. Projekt zu Projekt. Überschüssige Gelder werden aber stets in weitere Bäume bzw. Klimaschutzprojekte investiert.</p>
        </Collapsible>
    </div>
  );
}

export default Faq;
