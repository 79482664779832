import React, { useState, useEffect } from 'react';
import './style.css';
import { Row, Col } from 'react-flexbox-grid';
import axios from 'axios';
import { PayPalButton } from 'react-paypal-button-v2';

import Quantity from './partials/quantity';
import Personals from './partials/personals';
import Payment from './partials/payment';
import Summary from './partials/summary';

import { API, STRIPE, PAYPAL_CLIENT_ID } from '../../utils/settings';

function Order(props) {

    const [orderQuantity, setOrderQuantity] = useState([5]);
    const [orderFirstName, setOrderFirstName] = useState('');
    const [orderLastName, setOrderLastName] = useState('');
    const [orderEmail, setOrderEmail] = useState('');
    const [orderCompany, setOrderCompany] = useState('');
    const [orderPaymentType, setOrderPaymentType] = useState('stripe');
    const [orderTerms, setOrderTerms] = useState(false);
    const [orderErrors, setOrderErrors] = useState([]);
    const [isOrderValid, setIsOrderValid] = useState(false);


    function validateOrder() {
        setOrderErrors([]);
        let formErrors = [];

        if (orderFirstName === '') formErrors.push('Bitte gib deinen Vornamen ein.');
        if (orderLastName === '') formErrors.push('Bitte gib deinen Nachnamen ein.');
        if (orderEmail === '') formErrors.push('Bitte gib deine E-Mail Adresse ein.');
        if (!validateEmail(orderEmail)) formErrors.push('Bitte gib eine valide E-Mail Adresse ein.');
        if (orderTerms === false) formErrors.push('Bitte akzeptiere die Bedingungen.');

        if (formErrors.length > 0) {
            setOrderErrors(formErrors);
            return false;
        } else {
            setIsOrderValid(true);
            setOrderErrors([]);
            return true;
        }
    }

    function validateEmail(email) {
        if (/(?!^[.+&'_-]*@.*$)(^[_\w\d+&'-]+(\.[_\w\d+&'-]*)*@[\w\d-]+(\.[\w\d-]+)*\.(([\d]{1,3})|([\w]{2,}))$)/.test(email)) return true;
        else return false;
    }


    useEffect(() => {
        if (isOrderValid && orderPaymentType === 'paypal') {
            document.getElementById('paypal-buttons-container').style.display = 'block';
            document.getElementById('stripe-buttons-container').style.display = 'none';
            document.getElementById('error-buttons-container').style.display = 'none';
        } 
        else if (isOrderValid && orderPaymentType === 'stripe') {
            document.getElementById('paypal-buttons-container').style.display = 'none';
            document.getElementById('error-buttons-container').style.display = 'none';
            document.getElementById('stripe-buttons-container').style.display = 'block';
        }
        else {
            document.getElementById('paypal-buttons-container').style.display = 'none';
            document.getElementById('stripe-buttons-container').style.display = 'none';
            document.getElementById('error-buttons-container').style.display = 'block';
        }
    }, [isOrderValid, orderPaymentType]);


    useEffect(() => {
        if (orderFirstName !== '' && orderLastName !== '' && orderEmail !== '' && orderTerms !== false) {
            setIsOrderValid(true);
        } else {
            validateOrder();
            setIsOrderValid(false);
        }
    }, [orderFirstName, orderLastName, orderEmail, orderTerms]);


    function renderPayPalBtn() {
        let propz = props;

        if (isOrderValid && orderPaymentType === 'paypal') {
            return (
                <PayPalButton
                    amount={(orderQuantity[0] * 2.0) + 0.5}
                    options={{
                        currency: 'EUR',
                        clientId: PAYPAL_CLIENT_ID,
                        disableFunding: 'card,credit,eps'
                    }}
                    shippingPreference="NO_SHIPPING"
                    onSuccess={(details, data) => {  
                        props.setLoading(true);
                        axios
                        .post(API + '/payments/paypal/completed/', {
                            quantity: orderQuantity[0],
                            first_name: orderFirstName,
                            last_name: orderLastName,
                            company: orderCompany,
                            email: orderEmail,
                            referrer: 'corp-lp',
                            slug: propz.slug,
                            order_id: data.orderID
                        })
                        .then(request => {
                            window.location.href = window.location.href + 'completed/';
                            props.setLoading(false);
                        });
                    }}
                />
            );
        }
    }


    function finishOrder() {
        
        if (!isOrderValid) return false;

        switch (orderPaymentType) {
            case 'stripe':

                props.setLoading(true);
                
                axios
                .post(API + '/payments/stripe/session/', {
                    quantity: orderQuantity[0],
                    first_name: orderFirstName,
                    last_name: orderLastName,
                    company: orderCompany,
                    email: orderEmail,
                    success_url: window.location.href + 'completed/',
                    cancel_url: window.location.href,
                    description: 'Retten wir die Welt. Baum für Baum.',
                    name: '1-2-Tree',
                    referrer: 'corp-lp',
                    slug: props.slug
                })
                .then(request => {
                    STRIPE.redirectToCheckout({
                        sessionId: request.data.session_id
                    }).then(function(result) {
                        if (result.error.message) {
                            props.setLoading(false);
                            alert(result.error.message);
                        };
                    });
                });

                break;
        
            case 'paypal':
                break;
        }
    }


    function handleTermsChange(e) {
        if (e.target.checked) setOrderTerms(true);
        else setOrderTerms(false);
    }


    return (
        <React.Fragment>
            <div className="order">
                <Row>
                    <Col xs={12}>
                        <Quantity 
                            orderQuantity={orderQuantity} 
                            setOrderQuantity={setOrderQuantity} 
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Personals
                            orderFirstName={orderFirstName} setOrderFirstName={setOrderFirstName}
                            orderLastName={orderLastName} setOrderLastName={setOrderLastName}
                            orderEmail={orderEmail} setOrderEmail={setOrderEmail}
                            orderCompany={orderCompany} setOrderCompany={setOrderCompany}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Payment
                            orderPaymentType={orderPaymentType} setOrderPaymentType={setOrderPaymentType}
                        />
                    </Col>
                </Row>
            </div>
            <div className={'summary'}>
                <Row>
                    <Col xs={12}>
                        <Summary
                            orderTerms={orderTerms} setOrderTerms={setOrderTerms}
                            orderQuantity={orderQuantity} orderPaymentType={orderPaymentType}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className={'terms'}>
                            <h3>Bedingungen</h3>
                            <label>
                                <input type="checkbox" name="terms" value="true" onChange={e => handleTermsChange(e)} /> Ich bin mit der beschriebenen <a href="https://www.treemates.net/datenschutzerklaerung/" target="_blank">Datennutzung</a> einverstanden. 
                            </label>
                        </div>
                    </Col>
                </Row>
                <div className={'submission'}>
                    <Row>
                        {
                            !isOrderValid && orderErrors.length > 0 ?
                            <Col xs={12} md={6}>
                                <div className={'errors'}>
                                    <p>Folgende Fehler sind aufgetreten:</p>
                                    <ul>
                                        {orderErrors.map((error, i) => {
                                            return <li key={'error-' + i}>{error}</li>
                                        })}
                                    </ul>
                                </div>
                            </Col>
                            : null
                        }
                        <Col xs={12} md={!isOrderValid ? 6 : 12}>
                            <button id="error-buttons-container" className={'btn-cta btn-block'} disabled>Bezahlen & abschließen</button>
                            <button id="stripe-buttons-container" className={'btn-cta btn-block'} onClick={finishOrder}>Bezahlen & abschließen</button>
                            <div id="paypal-buttons-container">
                                {renderPayPalBtn()}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Order;
