import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './style.css';
import { Row, Col } from 'react-flexbox-grid';
import axios from 'axios';
import { PayPalButton } from 'react-paypal-button-v2';

import Quantity from './partials/quantity';
import Personals from './partials/personals';
import Payment from './partials/payment';
import Summary from './partials/summary';

import { API } from '../../utils/settings';


function ManualForm(props) {

    const [orderQuantity, setOrderQuantity] = useState([1]);
    const [orderFirstName, setOrderFirstName] = useState('');
    const [orderLastName, setOrderLastName] = useState('');
    const [orderEmail, setOrderEmail] = useState('');
    const [orderTerms, setOrderTerms] = useState(false);
    const [orderErrors, setOrderErrors] = useState([]);
    const [isOrderValid, setIsOrderValid] = useState(false);
    const [orderSuccess, setOrderSuccess] = useState(false);

    let { hash } = useParams();

    useEffect(() => {
        if (!hash) return <p>No hash supplied…</p>
    }, [hash])

    function validateOrder() {
        setOrderErrors([]);
        let formErrors = [];

        if (orderFirstName === '') formErrors.push('Bitte gib deinen Vornamen ein.');
        if (orderLastName === '') formErrors.push('Bitte gib deinen Nachnamen ein.');
        if (orderEmail === '') formErrors.push('Bitte gib deine E-Mail Adresse ein.');
        if (!validateEmail(orderEmail)) formErrors.push('Bitte gib eine valide E-Mail Adresse ein.');
        if (orderTerms === false) formErrors.push('Bitte akzeptiere die Bedingungen.');

        if (formErrors.length > 0) {
            setOrderErrors(formErrors);
            return false;
        } else {
            setIsOrderValid(true);
            setOrderErrors([]);
            return true;
        }
    }

    function validateEmail(email) {
        if (/(?!^[.+&'_-]*@.*$)(^[_\w\d+&'-]+(\.[_\w\d+&'-]*)*@[\w\d-]+(\.[\w\d-]+)*\.(([\d]{1,3})|([\w]{2,}))$)/.test(email)) return true;
        else return false;
    }

    useEffect(() => {
        if (orderFirstName !== '' && orderLastName !== '' && orderEmail !== '' && orderTerms !== false) {
            setIsOrderValid(true);
        } else {
            validateOrder();
            setIsOrderValid(false);
        }
    }, [orderFirstName, orderLastName, orderEmail, orderTerms]);

    function finishOrder() {
        if (!isOrderValid) return;
        
        axios
        .post(API + '/plant/', 
        {
            quantity: orderQuantity[0],
            first_name: orderFirstName,
            last_name: orderLastName,
            email: orderEmail,
            referrer: 'manual-form'
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'API-KEY': hash
            }
        })
        .then(request => {
            setOrderSuccess(true)
            setOrderQuantity([1])
        });

    }


    function handleTermsChange(e) {
        if (e.target.checked) setOrderTerms(true);
        else setOrderTerms(false);
    }


    return (
        !orderSuccess ?
            <React.Fragment>
                <div className="order">
                    <Row>
                        <Col xs={12}>
                            <div className="personals">
                                <h2>1. Persönliche Daten</h2>
                                <p className={'intro'}><i>Damit wir die Spende personalisieren und das Zertifikat zusenden können.</i></p>
                                <div className="form-row">
                                    <label htmlFor="first_name">Vorname*</label>
                                    <input required type="text" name="first_name" onChange={e => setOrderFirstName(e.target.value)} />
                                </div>
                                <div className="form-row">
                                    <label htmlFor="last_name">Nachname*</label>
                                    <input required type="text" name="last_name" onChange={e => setOrderLastName(e.target.value)} />
                                </div>
                                <div className="form-row">
                                    <label htmlFor="first_name">E-Mail*</label>
                                    <input required type="email" name="email" onChange={e => setOrderEmail(e.target.value)} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Quantity 
                                orderQuantity={orderQuantity} 
                                setOrderQuantity={setOrderQuantity} 
                                minQuantity={1}
                                step={1}
                                count={2}
                            />
                        </Col>
                    </Row>
                </div>
                <div className={'summary'}>
                    <Row>
                        <Col xs={12}>
                            <div className={'terms'}>
                                <h3>Bedingungen</h3>
                                <label>
                                    <input type="checkbox" name="terms" value="true" onChange={e => handleTermsChange(e)} /> Ich bin mit der beschriebenen <a href="https://www.treemates.net/datenschutzerklaerung/" target="_blank">Datennutzung</a> einverstanden. 
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <div className={'submission'}>
                        <Row>
                            {  
                                !isOrderValid && orderErrors.length > 0 ?
                                <Col xs={12} md={6}>
                                    <div className={'errors'}>
                                        <p>Folgende Fehler sind aufgetreten:</p>
                                        <ul>
                                            {orderErrors.map((error, i) => {
                                                return <li key={'error-' + i}>{error}</li>
                                            })}
                                        </ul>
                                    </div>
                                </Col>
                                : null
                            }
                            <Col xs={12} md={!isOrderValid ? 6 : 12}>
                                <button className={'btn-cta btn-block'} onClick={() => finishOrder()}>Zertifikat absenden</button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        : 
        <div className="completed">

                    <h1>Unser Planet sagt Danke!</h1>
                    <p>Danke, dass du dich gemeinsam mit <b>{props.profile.company}</b> für den Schutz unseres Planeten einsetzt.</p>
                    <p>Du erhältst in den nächsten Minuten dein <b>personalisiertes TreeMates-Zertifikat</b> via E-Mail. Bei Fragen zu TreeMates oder deinem Kauf, sende uns einfach eine E-Mail an <a href="mailto:hello@treemates.net">hello@treemates.net</a>.</p>
                    <button className={'btn-cta btn-block'} onClick={() => setOrderSuccess(false)}>Neues Zertifikat senden</button>
        </div>
    );
}

export default ManualForm;
