import React, { useState, useEffect } from 'react';
import './App.css';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { BrowserRouter as Router, Switch, Route, useParams, Redirect } from 'react-router-dom';
import axios from 'axios';

import Home from './views/home';

import { API } from './utils/settings';


function App() {
  return (
    <Router>
      <Switch>
        <Route path="/:url*" exact strict render={props => <Redirect to={`${props.location.pathname}/`}/>}/>
        <Route exact path="/" children={<NotFoundRoute />} />
        <Route path="/:slug" children={<HomeRoute />} />
      </Switch>
    </Router>
  );
}

function HomeRoute() {

  const [profile, setProfile] = useState();

  let { slug } = useParams();

  useEffect(() => {
    axios
      .get(
        API + '/auth/profile/?slug=' + slug
      )
      .then(({ data }) => {
        setProfile(data);
      })
      .catch(error => {
        return window.location.href = 'https://www.treemates.net';
      });
  }, []);

  return profile ? (
    <Home profile={profile} />
  ) : null;
}

function NotFoundRoute() {
  return window.location.href = 'https://www.treemates.net';
}

export default App;
