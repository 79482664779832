import React, { useState } from 'react';
import './style.css';
import { Grid } from 'react-flexbox-grid';
import { BrowserRouter as Switch, Route } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import { Helmet } from 'react-helmet';

import Header from '../../components/header';
import Hero from '../../components/hero';
import Order from '../../components/order';
import ManualForm from '../../components/order/form';
import Faq from '../../components/faq';
import Footer from '../../components/footer';
import Completed from '../../components/completed';


function Home(props) {
  return (
    <Switch>
      <Route exact path="/:slug/form/:hash/" children={<Form profile={props.profile} />} />
      <Route exact path="/:slug/completed/" children={<PlantCompleted profile={props.profile} />} />
      <Route exact path="/:slug/" children={<Plant profile={props.profile} />} />
    </Switch>
  );
}

function Plant(props) {

  const [loading, setLoading] = useState(false);

  return (
    <LoadingOverlay active={loading} spinner text='Bitte warten…'>
      <Helmet>
        <title>{props.profile.company} | TreeMates.net</title>
      </Helmet>
      <div className="wrapper">
        <Grid fluid>
          <Header profile={props.profile} />
          <Hero profile={props.profile} />
          <Order slug={props.profile.slug} setLoading={setLoading} />
          <Faq />
          <Footer />
        </Grid>
      </div>
    </LoadingOverlay>
  );
}

function PlantCompleted(props) {
  return (
    <React.Fragment>
      <Helmet>
        <title>{props.profile.company} | TreeMates.net</title>
      </Helmet>
      <div className="wrapper">
        <Grid fluid>
          <Header profile={props.profile} />
          <Completed profile={props.profile} />
          <Faq />
          <Footer />
        </Grid>
      </div>
    </React.Fragment>
  )
}

function Form(props) {
  return (
    <React.Fragment>
      <Helmet>
        <title>{props.profile.company} | TreeMates.net</title>
      </Helmet>
      <div className="wrapper">
        <Grid fluid>
          <Header profile={props.profile} />
          <ManualForm profile={props.profile} />
          <Footer />
        </Grid>
      </div>
    </React.Fragment>
  )
}

export default Home;
