import React from 'react';
import './style.css';
import { Row, Col } from 'react-flexbox-grid';


function Footer(props) {
  return (
    <div className={'footer'}>
        <p>
            © {new Date().getFullYear()} TreeMates - Verein für Klimaschutz im E-Commerce.<br />
            Alle Rechte vorbehalten. <a href={'https://www.treemates.net/impressum/'} target={'_blank'}>Impressum</a> | <a href={'https://www.treemates.net/datenschutzerklaerung/'} target={'_blank'}>Datenschutzerklärung</a>
        </p>
    </div>
  );
}

export default Footer;
