import React from 'react';
import './style.css';
import { Grid, Row, Col } from 'react-flexbox-grid';

import Header from '../header';


function Completed(props) {
  return (
    <div className="completed">
        <h1>Unser Planet sagt Danke!</h1>
        <p>Danke, dass du dich gemeinsam mit <b>{props.profile.company}</b> für den Schutz unseres Planeten einsetzt.</p>
        <p>Du erhältst in den nächsten Minuten dein <b>personalisiertes TreeMates-Zertifikat</b> via E-Mail. Bei Fragen zu TreeMates oder deinem Kauf, sende uns einfach eine E-Mail an <a href="mailto:hello@treemates.net">hello@treemates.net</a>.</p>
        <p className={'btn-mt'}><a href={'/' + props.profile.slug + '/'} className={'btn-cta'}>Zurück zur Startseite</a></p>
    </div>
  );
}

export default Completed;
