import React from 'react';
import './style.css';
import { Row, Col } from 'react-flexbox-grid';
import tm_logo from '../../assets/img/tm_logo.svg';


function Header(props) {
  return (
    <header className="header">
        <Row>
            <Col xs={6} className={'logo'}>
                <img src={tm_logo} />
            </Col>
            <Col xs={6} className={'logo-client'}>
                <img src={props.profile.logo.medium} />
            </Col>
        </Row>
    </header>
  );
}

export default Header;
