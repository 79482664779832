import React, { useState, useEffect } from 'react';
import './style.css';
import { Row, Col } from 'react-flexbox-grid';
import axios from 'axios';


function Summary(props) {

    return (
        <React.Fragment>
            <h2>Zusammenfassung</h2>
            <div className={'details'}>
                <div className={'col'}>
                    <p>{props.orderQuantity} <small>Bäume</small></p>
                </div>
                <div className={'col'}>
                    {props.orderPaymentType === 'stripe' ? <p>€ {Number(props.orderQuantity * 2.0).toLocaleString("de-AT", {minimumFractionDigits: 2})}</p> : null}
                    {props.orderPaymentType === 'paypal' ? <p>€ {Number((props.orderQuantity * 2.0) + 0.5).toLocaleString("de-AT", {minimumFractionDigits: 2})} <small>+ € 0,50 Gb.</small></p> : null}
                </div>
                <div className={'col'}>
                    <p>
                        {props.orderPaymentType === 'stripe' ? <React.Fragment><i className="paymenticon pf pf-credit-card"></i></React.Fragment> : null}
                        {props.orderPaymentType === 'paypal' ? <React.Fragment><i className="paymenticon pf pf-paypal"></i></React.Fragment> : null}
                    </p>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Summary;