import React, { useState, useEffect } from 'react';
import './style.css';
import { Row, Col } from 'react-flexbox-grid';
import axios from 'axios';
import '../../../../assets/css/paymentfont.css';


function Payment(props) {

    return (
        <div className="payment">
            <h2>3. Wie willst du bezahlen?</h2>
            <p className={'intro'}><i>Bitte wähle deine bevorzugte Zahlungsart aus.</i></p>
            <div className={'methods'}>
                <div className="form-row">
                    <label>
                        <input 
                            type="radio" 
                            name="payment_type" 
                            value="stripe" 
                            checked={props.orderPaymentType === 'stripe'} 
                            onChange={e => props.setOrderPaymentType('stripe')}
                        /> <b>Kreditkarte</b>
                    </label>
                    <div className={'icons'}>
                        <i className="paymenticon pf pf-visa"></i>
                        <i className="paymenticon pf pf-mastercard"></i>
                        <i className="paymenticon pf pf-apple-pay"></i>
                    </div>
                </div>
                <div className="form-row">
                    <label>
                        <input 
                            type="radio" 
                            name="payment_type" 
                            value="paypal" 
                            checked={props.orderPaymentType === 'paypal'} 
                            onChange={e => props.setOrderPaymentType('paypal')}
                        /> <b>PayPal / SOFORT</b>
                    </label>
                    <div className={'icons'}>
                        <i className="paymenticon pf pf-paypal"></i>
                        <i className="paymenticon pf pf-sofort"></i>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Payment;