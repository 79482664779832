import React, { useState, useEffect } from 'react';
import './style.css';
import { Row, Col } from 'react-flexbox-grid';
import axios from 'axios';


function Personals(props) {

    return (
        <div className="personals">
            <h2>2. Wer bist du?</h2>
            <p className={'intro'}><i>Damit wir deine Spende personalisieren und dir dein Zertifikat zusenden können.</i></p>
            <div className="form-row">
                <label htmlFor="first_name">Vorname*</label>
                <input type="text" name="first_name" onChange={e => props.setOrderFirstName(e.target.value)} />
            </div>
            <div className="form-row">
                <label htmlFor="last_name">Nachname*</label>
                <input type="text" name="last_name" onChange={e => props.setOrderLastName(e.target.value)} />
            </div>
            <div className="form-row">
                <label htmlFor="first_name">E-Mail*</label>
                <input type="email" name="email" onChange={e => props.setOrderEmail(e.target.value)} />
            </div>
            <div className="form-row">
                <label htmlFor="company">Firma</label>
                <input type="text" name="company" onChange={e => props.setOrderCompany(e.target.value)} />
            </div>
        </div>
    );
}

export default Personals;