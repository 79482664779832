import React, { useState, useEffect } from 'react';
import './style.css';
import { Row, Col } from 'react-flexbox-grid';
import axios from 'axios';
import { Range } from 'react-range';

import tree from '../../../../assets/img/tree.svg';


function Quantity(props) {

    let propz = props;

    function renderPrice() {
        return (
            <div className={'pricetag'}>
                1<img src={tree} className={'tree'} /> = €2
            </div>
        )
    }

    return (
        <div className="quantity">
            {renderPrice()}
            <h2>{props.count ? props.count : 1}. Wie viele Bäume?</h2>
            <div className={'quantity-slider'}>
                <Range
                    step={props.step ? props.step : 5}
                    min={props.minQuantity ? props.minQuantity : 5}
                    max={250}
                    values={props.orderQuantity}
                    onChange={values => props.setOrderQuantity(values)}
                    renderTrack={({ props, children }) => (
                        <div
                            {...props}
                            style={{
                            ...props.style,
                            height: '15px',
                            width: '100%',
                            backgroundColor: '#EAF4D6',
                            borderRadius: 6
                            }}
                        >
                            {children}
                        </div>
                    )}
                    renderThumb={({ props }) => (
                        <div
                            {...props}
                            style={{
                            ...props.style,
                            height: '40px',
                            width: '40px',
                            backgroundColor: '#588266',
                            color: '#ffffff',
                            fontSize: '1.1em',
                            fontWeight: 600,
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            lineHeight: '40px',
                            boxShadow: '0px 3px 6px 0px rgba(0,0,0,0.16)'
                            }}
                        >{propz.orderQuantity}</div>
                    )}
                />
            </div>
            <p className={'intro'}><i>Du wirst <b>€ {props.orderQuantity * 2.0}</b> ({props.orderQuantity} Bäume) ausgeben.</i></p>
        </div>
    );
}

export default Quantity;
