var apiUrl = 'https://api.treemates.net';
var stripeKey = 'pk_live_WLyKIDFAsZHlamZeKRPpZwqW00UpFElFoC'
var paypalKey = 'AbmROYQtDBuspycKfN2jXznft7awqWe6nv6YLJJKU7Su2UnYxGeh0B6D24mqmCrHtB4gx1fGeXjrtRBs';

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    var apiUrl = 'https://api.treemates.net';
    stripeKey = 'pk_test_iRLfIhK1MRCjpmjFcTky93jl00pA3Ly7Bm';
    paypalKey = 'AcTLjSiDjZSouX3xYmJEklNhNVcyBGRc4B00DTiRclErtKQ2tsYMgkHhBan8ZQ0vCR_ujqa2uvQ6EbcY';
}

export const API = apiUrl;
export const STRIPE = window.Stripe(stripeKey);
export const PAYPAL_CLIENT_ID = paypalKey;