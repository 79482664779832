import React, { useState, useEffect } from 'react';
import './style.css';
import { Row, Col } from 'react-flexbox-grid';
import axios from 'axios';
import animateScrollTo from 'animated-scroll-to';
import hero_bg from '../../assets/img/hero-bg.png';
import Snowfall from 'react-snowfall'

import { API } from '../../utils/settings';


function Hero(props) {

    const [treeCount, setTreeCount] = useState(0);

    useEffect(() => {
        fetchStats();
        setInterval(() => {
            fetchStats();            
        }, 5000);
    }, []);

    function fetchStats() {
        axios
        .get(API + '/auth/stats/?slug=' + props.profile.slug)
        .then(({ data }) => {
            if (treeCount !== data.tree_count) setTreeCount(data.tree_count);
        })
        .catch(error => {
            console.log(error);
        });
    }

    function renderSnow() {
        var givenDate = '2020-01-07';
        var currentDate = new Date();
        givenDate = new Date(givenDate);

        if (currentDate <= givenDate) return <Snowfall />
        else return null;
    }

    return (
        <React.Fragment>
            {renderSnow()}
            <div className="hero">
                <Row>
                    <Col xs={12} className={'section'}>
                        <h1>{props.profile.corporate.title}</h1>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className={'section corp-intro'} dangerouslySetInnerHTML={{__html: props.profile.corporate.intro}}></Col>
                </Row>
                <Row>
                    <Col xs={12} className={'section'}>
                        <span className={'treecount'}>{treeCount.toLocaleString('de-DE')}</span>
                        <small><i>{treeCount == 1 ? 'Baum' : 'Bäume'} wurden bereits gepflanzt</i></small>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className={'section'}>
                        <button className={'btn-cta'} onClick={() => animateScrollTo(document.querySelector('.order'))}>Jetzt mitmachen!</button>
                    </Col>
                </Row>
            </div>
            <div className={'hero-bg'} style={{ backgroundImage: `url(${hero_bg})` }}>
                <div style={{ height: 50, overflow: 'hidden', width: '100%' }}>
                    <svg viewBox={'0 0 500 100'} preserveAspectRatio={'none'} style={{ height: '100%', width: '100%' }}>
                        <path d={'M0.00,49.98 C149.99,150.00 349.20,-49.98 500.00,49.98 L500.00,150.00 L0.00,150.00 Z'} style={{ stroke: 'none', fill: '#ffffff' }}></path>
                    </svg>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Hero;
